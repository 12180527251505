/* eslint-disable  no-unused-expressions */
import { getAuthCookie } from '@skilltype/services/lib/auth'
import { navigate } from '@reach/router'
import {
  requestedUrlUtil,
  joinOrganizationUtil,
} from '@skilltype/ui/lib/browserUtils'

const unauthenticatedRoutes = [
  '/login',
  '/login/',
  '/login/sso',
  '/signup',
  '/signup/',
  '/contact',
  '/contact/',
  '/userActivated',
  '/passwordReset',
  '/passwordReset/',
  '/passwordReset/new',
  '/passwordReset/new/',
  '/passwordReset/invalidToken',
  '/passwordReset/invalidToken/',
]
const isAuthenticated = getAuthCookie()
const pathname = window.location.pathname
const isJoinOrganizationPath = pathname.startsWith('/join/')
const isOnAuthenticatedPage =
  !unauthenticatedRoutes.includes(pathname) && !isJoinOrganizationPath

if (!isAuthenticated && !isOnAuthenticatedPage) {
  import('./index.unauthenticated')
} else if (isAuthenticated && isOnAuthenticatedPage) {
  import('./index.authenticated')
} else if (!isAuthenticated && isOnAuthenticatedPage) {
  const { pathname, search } = window.location
  requestedUrlUtil.setRequestedUrl(`${pathname}${search}`)
  navigate('/login')
  import('./index.unauthenticated')
} else if (isAuthenticated && !isOnAuthenticatedPage) {
  const { pathname } = window.location
  if (isJoinOrganizationPath) {
    joinOrganizationUtil.setInviteTokenFromPath(pathname)
  }
  navigate('/')
  import('./index.authenticated')
}
